
body {
    direction: rtl;
    min-height: inherit;
}
hr {
    border-style: solid;
    border-color: $main;
    border-width: 1px 0 0 0;
    margin:0.9em 0;
}
strong {
    font-weight: 400;
    font-variation-settings: "wght"600, "wdth"500;
}